import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { setupWorker } from './setupWorker';

const isProd = process.env.NODE_ENV === 'production';
const isDemo = process.env.IS_DEMO;
const sentryUrl = process.env.SENTRY_URL;
const env = isProd ? (isDemo ? 'staging' : 'production') : 'development';
isProd &&
  Sentry.init({
    dsn: `https://4ac61b2702a14c1f49343706b05e7c82@${sentryUrl}/9`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: env,
    // Performance Monitoring
    tracesSampleRate: isProd ? 1 : 0.1, //  Capture 100% of the transactions
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#sample-rate
    sampleRate: isProd ? 1 : 0.1,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https?:\/\/api-buffett\.[^\/]+\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    console.log('onUpdate', registration, registration.waiting);
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.waiting.addEventListener('statechange', (e: any) => {
        console.log('statechange', e.target.state);
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  },
});

// setupWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
